import React from "react";
import { queryArticles } from "../../../graphql/queries";
import ArticleCard from "./ArticleCard";
import useSWR from "swr";

const ArticleTeaser = (props) => {
  const { className, forceLoad } = props;

  const { data } = useSWR([
    queryArticles,
    JSON.stringify({
      action: "search",
      visibility: "public",
      locale,
      size: 1,
    }),
  ]);

  if (!data?.queryArticles?.items || data.queryArticles.items.length <= 0) return null;

  const article = data.queryArticles.items[0];

  return <ArticleCard className={className} article={article} variant="minimal" target="new" forceLoad={forceLoad} />;
};

export default ArticleTeaser;
