import React, { FC, useEffect, useRef } from "react";

import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/styles";

type TrustindexSnippetProps = { id: string };

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  main: {
    marginTop: 30,
    marginBottom: 35,
  },
}));

const TrustindexSnippet: FC<TrustindexSnippetProps> = ({ id }) => {
  const ref = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    const element = ref?.current;
    if (typeof window !== "undefined" && element) {
      const script = document.createElement("script");
      script.src = `https://cdn.trustindex.io/loader.js?${id}`;
      script.async = true;
      script.defer = true;
      element.appendChild(script);
      return () => {
        element.removeChild(script);
      };
    }
  }, [id, ref]);

  return <div className={classes.main} ref={ref} />;
};

export default TrustindexSnippet;
