import React, { useState } from "react";
import { BikeCompareStorage } from "./BikeCompareStorage";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.bikeCompareAddIconSize,
    height: theme.bikeCompareAddIconSize,
    cursor: "pointer",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  selected: {
    backgroundImage: "url('/images/Bike-delete.svg')",
  },
  unselected: {
    backgroundImage: "url('/images/Bike-add.svg')",
  },
}));

const Button = (props) => {
  const { seoId, className, flavor } = props;
  const classes = useStyles();
  const [isSelected, setIsSelected] = useState(BikeCompareStorage.isAdded(flavor, seoId));

  return (
    <div
      className={`${classes.root} ${className ? className : ""} ${isSelected ? classes.selected : classes.unselected}`}
      onClick={(e) => {
        e.preventDefault();
        if (isSelected) {
          BikeCompareStorage.removeBike(flavor, seoId);
          setIsSelected(false);
        } else {
          BikeCompareStorage.addBike(flavor, seoId);
          setIsSelected(true);
        }
      }}
    />
  );
};

export default Button;
