import Grid from "@material-ui/core/Grid";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme) => ({
  description: {
    textAlign: "center",
    width: "inherit",
  },
  section: {
    marginBottom: theme.spacing(5),
  },
  sectionContent: {
    display: "inline-block",
    maxWidth: "100%",
  },
  icon: {
    width: 40,
    height: 40,
    display: "inline-block",
    backgroundImage: "url(/images/Logo-Check.png)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  paragraph: {
    maxWidth: 450,
    textAlign: "left",
    marginBottom: theme.spacing(3),
    fontSize: 16,
    lineHeight: "24px",
    "& b": {
      fontWeight: 900,
    },
  },
  title: {
    fontSize: "x-large",
    fontWeight: 300,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

export const DescriptionParagraph = (props) => {
  const classes = useStyles();

  return <div className={`${classes.paragraph} FX-DescriptionParagraph`}>{props.children}</div>;
};

export const DescriptionSection = (props) => {
  const { title, hideIcon } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <div className={classes.section}>
      {!hideIcon && <div className={classes.icon} />}
      <h2 className={`${classes.title}`}>{title}</h2>
      <div className={classes.sectionContent}>{props.children}</div>
    </div>
  );
};

const Description = (props) => {
  const { className, variant, sections } = props;
  const classes = useStyles();

  return (
    <Grid container className={`${className ? className : ""} ${classes.description}`}>
      {React.Children.map(props.children, (child, i) => {
        return (
          <Grid item key={i} xs={variant == "fullwidth" ? 12 : 12} md={variant == "fullwidth" ? 12 : 6}>
            {child}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Description;
