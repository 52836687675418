import React, { FC } from "react";

import Link from "next/link";
import { TheCycleverseTheme } from "../../theme";
import { UrlObject } from "url";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  link: {
    textDecoration: "underline",
    color: theme.openButtonColor,
  },
  notStyled: {
    textDecoration: "inherit",
    color: "inherit",
  },
}));

type LocaleLinkProps = {
  as?: string | UrlObject;
  href: string | UrlObject;
  target?: "new";
  aClass?: string;
  preventDefault?: boolean;
  akey?: string;
  dataTest?: string;
  prefetch?: boolean;
  notStyled?: boolean;
  title?: string;
  onClick?: Function;
  externalLink?: boolean;
  anchor?: boolean;
  shallow?: boolean;
  dofollow?: boolean;
};

const LocaleLink: FC<LocaleLinkProps> = ({
  as,
  children,
  href,
  target,
  aClass,
  preventDefault,
  akey,
  dataTest,
  prefetch,
  notStyled,
  title,
  onClick = () => {},
  externalLink = false,
  anchor = false,
  shallow = false,
  dofollow = false,
}) => {
  const { lang } = useTranslation("common");
  const classes = useStyles();

  var additionalProps: any = {};

  if (target === "new") {
    additionalProps.target = "_blank";
  }

  if (externalLink) {
    additionalProps.target = "_blank";
    additionalProps.rel = dofollow ? "noopener external" : "noopener nofollow external";
    additionalProps.href = href;
  }

  if (anchor) {
    additionalProps.href = `#${href}`;
  }

  const aElement = (
    <a
      className={`${notStyled ? classes.notStyled : classes.link} ${aClass ? aClass : ""}`}
      hrefLang={lang}
      onClick={(e) => {
        onClick();
        preventDefault && e.preventDefault();
      }}
      key={akey}
      data-test={dataTest ? dataTest : ""}
      title={title}
      {...additionalProps}
    >
      {children}
    </a>
  );

  if (anchor || externalLink) {
    return aElement;
  }

  if (typeof href === "object" && href["pathname"]) {
    if (!href["pathname"].startsWith(`/${lang}/`)) {
      href["pathname"] = `/${lang}${href["pathname"]}`;
    }
  }

  return (
    <Link
      passHref
      href={typeof href === "string" ? `/${lang}${href}` : href}
      as={as && typeof as === "string" ? `/${lang}${as}` : as}
      prefetch={prefetch}
      shallow={shallow}
      locale={lang}
    >
      {aElement}
    </Link>
  );
};

export default LocaleLink;
