import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  headline: {
    ...theme.headline1,
  },
  alignCenter: {
    textAlign: "center",
  },
}));

const CardHighlightsVertical = (props) => {
  const { title, subtitle, cards, className, itemClass, align, typographyVariant } = props;
  const typoVariant = typographyVariant || "h6";
  const classes = useStyles();

  return (
    <div className={className ? className : ""}>
      {title && (
        <Typography
          variant="h6"
          component="h2"
          className={`${classes.headline} ${align == "center" ? classes.alignCenter : ""}`}
        >
          {title}
        </Typography>
      )}

      {subtitle && <div className={`${align == "center" ? classes.alignCenter : ""}`}>{subtitle}</div>}

      <Grid container>
        {cards.map((card, i) => (
          <Grid
            key={i}
            item
            xs={12}
            className={`${itemClass ? itemClass : ""} ${align == "center" ? classes.alignCenter : ""}`}
          >
            {card}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CardHighlightsVertical;
