import React, { useEffect, useMemo, useRef } from "react";

import Analytics from "../../lib/analytics/analytics";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    margin: theme.cardSpacing,
    backgroundColor: theme.palette.background.secondary,
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  cardContent: {
    // paddingBottom: "100%",
    minHeight: 300,
    height: "100%",
    paddingLeft: 0,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
}));

function useIsInViewport(ref, fn) {
  const observer = useMemo(
    () =>
      typeof window !== "undefined"
        ? new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
              fn();
            }
          })
        : null,
    [fn]
  );

  useEffect(() => {
    observer?.observe(ref.current);

    return () => {
      observer?.disconnect();
    };
  }, [ref, observer]);
}

const AdCard = (props) => {
  const { ad } = props;
  const classes = useStyles();
  const ref = useRef(null);

  useIsInViewport(ref, () => {
    Analytics.adImpression(ad.link, {});
  });

  const handleClick = (ad) => {
    Analytics.adClick(ad.link, {});
  };

  return (
    <div
      ref={ref}
      className={classes.card}
      style={{ backgroundImage: `url(${ad.imgBg})` }}
      onClick={() => handleClick(ad)}
    >
      <a className={classes.link} href={ad.link} target="_blank" rel="noopener nofollow">
        <div className={classes.cardContent} style={{ backgroundImage: `url(${ad.imgFg})` }} />
      </a>
    </div>
  );
};

export default AdCard;
