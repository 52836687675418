import AdvertiserCard from "./AdvertiserCard";
import CardHighlightsHorizontal from "../CardHighlightsHorizontal";
import CardHighlightsVertical from "../CardHighlightsVertical";
import ProductCard from "./ProductCard";
import ProductFeed from "./ProductFeed";
import React from "react";
import { TheCycleverseTheme } from "src/theme";
import { default as featuredProducts } from "./data/data.json";
import { makeStyles } from "@material-ui/core/styles";
import { queryProducts } from "../../../graphql/queries";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useRouter } from "next/router";
import useSWR from "swr";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  productHighlights: {
    marginBottom: 32,
  },
  feed: {
    maxWidth: "100%",
  },
}));

export type CardType = "Product" | "Advertiser";

export type ResolvedProduct = any;

export type ProductsArray = Array<ResolvedProduct>;

export type TitleResolver = (ProductsArray) => string;

export type TypographyVariant = "h2";

export type AlignValue = "center" | "left" | "right";

export type ProductsPostprocessor = (ProductsArray) => ProductsArray;

export type ProductPayloadsRecommendation = {
  type: "productPayloads";
  products: ProductsArray;
};

export type SameEanOrSeoIdRecommendation = {
  type: "sameEanOrSeoId";
  product?: ResolvedProduct;
  seoId: string;
};

export type IdsRecommendation = {
  type: "ids";
  ids: Array<string>;
};

export type TopHitsByBrandRecommendation = {
  type: "topHitsByBrand";
  brands: Array<string>;
  priceMin: number;
  priceMax: number;
  category: string;
  sort: string;
};

export type SearchRecommendation = {
  type: "search";
  searchString: string;
  brands: Array<string>;
  priceMin: number;
  priceMax: number;
  categories: Array<string>;
  sort: string;
};

export type FeaturedRecommendation = {
  type: "featured";
};

export type SameBrandRecommendation = {
  type: "sameBrand";
  product: ResolvedProduct;
};

export type SameCategoryRecommendation = {
  type: "sameCategory";
  product: ResolvedProduct;
};

export type Recommendation =
  | ProductPayloadsRecommendation
  | SameEanOrSeoIdRecommendation
  | IdsRecommendation
  | TopHitsByBrandRecommendation
  | SearchRecommendation
  | FeaturedRecommendation
  | SameBrandRecommendation
  | SameCategoryRecommendation;

export type ProductHighlightsProps = {
  anchor?: string;
  recommendation: Recommendation;
  minimized?: boolean;
  highlightsClass?: any;
  horizontalScrollClassName?: string;
  title?: string;
  verticalScroll?: boolean;
  productsPostprocessor?: undefined | ProductsPostprocessor;
  align?: AlignValue;
  itemClass?: any;
  getTitle?: TitleResolver;
  getSubTitle?: TitleResolver;
  forceLoad?: boolean;
  typographyVariant?: TypographyVariant;
  cardType?: CardType;
};

const getOutput = (
  classes,
  matchesXS,
  products,
  highlightsClass,
  horizontalScrollClassName,
  title,
  verticalScroll,
  productsPostprocessor,
  anchor: string,
  align: AlignValue,
  itemClass: any,
  getTitle: TitleResolver,
  getSubTitle: TitleResolver,
  forceLoad: boolean,
  typographyVariant: TypographyVariant,
  cardType: CardType
) => {
  if (!products || products.length <= 0) {
    return null;
  }

  return (
    <div className={classes.productHighlights} id={anchor}>
      {matchesXS ? (
        verticalScroll ? (
          <CardHighlightsVertical
            typographyVariant={typographyVariant}
            className={highlightsClass ? highlightsClass : ""}
            itemClass={itemClass}
            title={getTitle ? getTitle(products) : title}
            subtitle={getSubTitle ? getSubTitle(products) : undefined}
            align={align}
            cards={(productsPostprocessor ? productsPostprocessor(products) : products).map((product) =>
              cardType === "Advertiser" ? (
                <AdvertiserCard key={product.id} product={product} />
              ) : (
                <ProductCard key={product.id} forceLoad={forceLoad ? forceLoad : 1} product={product} />
              )
            )}
          />
        ) : (
          <CardHighlightsHorizontal
            className={highlightsClass ? highlightsClass : ""}
            horizontalScrollClassName={!verticalScroll ? horizontalScrollClassName : ""}
            title={title}
            cards={products.map((product) => (
              <ProductCard key={product.id} forceLoad={forceLoad ? forceLoad : 1} product={product} />
            ))}
          />
        )
      ) : (
        <div className={classes.feed}>
          <ProductFeed products={products} />
        </div>
      )}
    </div>
  );
};

const ProductHightlights = (props: ProductHighlightsProps) => {
  const {
    anchor,
    recommendation,
    minimized,
    highlightsClass,
    horizontalScrollClassName,
    title,
    verticalScroll,
    productsPostprocessor,
    align,
    itemClass,
    getTitle,
    getSubTitle,
    forceLoad,
    typographyVariant,
    cardType,
  } = props;
  const classes = useStyles();
  const mediaType = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.down("xs"));
  const matchesXS = minimized == true ? true : mediaType;
  const router = useRouter();

  if (recommendation?.type == "productPayloads") {
    return getOutput(
      classes,
      matchesXS,
      recommendation.products,
      highlightsClass,
      horizontalScrollClassName,
      title,
      verticalScroll,
      productsPostprocessor,
      anchor,
      align,
      itemClass,
      getTitle,
      getSubTitle,
      forceLoad,
      typographyVariant,
      cardType
    );
  }

  if (recommendation?.type == "featured") {
    return getOutput(
      classes,
      matchesXS,
      featuredProducts.highlights,
      highlightsClass,
      horizontalScrollClassName,
      title,
      verticalScroll,
      productsPostprocessor,
      anchor,
      align,
      itemClass,
      getTitle,
      getSubTitle,
      forceLoad,
      typographyVariant,
      cardType
    );
  }

  const getFilter = () => {
    if (recommendation?.type === "topHitsByBrand") {
      const r: TopHitsByBrandRecommendation = recommendation;
      return {
        action: r.type,
        category: r.category,
        brands: r.brands.join(","),
        priceMin: r.priceMin,
        priceMax: r.priceMax,
        sort: r.sort,
      };
    }

    const config = {
      action: "search",
      visibility: "public",
      size: 8,
      sort: recommendation?.type == "sameEanOrSeoId" ? "priceAsc" : "default",
    };

    if (recommendation?.type == "ids") {
      return {
        ...config,
        idFilter: recommendation.ids.join(","),
      };
    }

    if (recommendation?.type == "sameCategory") {
      return {
        ...config,
        category: recommendation.product.category,
        excludeIds: recommendation.product.id,
      };
    }

    if (recommendation?.type == "sameBrand") {
      return {
        ...config,
        brands: recommendation.product.brand,
        excludeIds: recommendation.product.id,
      };
    }

    if (recommendation?.type == "sameEanOrSeoId") {
      return {
        action: "productOffers",
        ean: recommendation.product?.ean,
        seoId: recommendation.seoId,
      };
    }

    if (recommendation?.type == "search") {
      return {
        ...config,
        searchString: recommendation.searchString,
        categories: recommendation.categories.join(","),
        brands: recommendation.brands.join(","),
        priceMin: recommendation.priceMin,
        priceMax: recommendation.priceMax,
        sort: recommendation.sort,
      };
    }

    console.error("Not implemented ProductHighlights filter");
    return {};
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useSWR([queryProducts, JSON.stringify(getFilter())]);

  if (!data) {
    return null;
  }
  var products = data?.queryProducts?.items;

  if (!products || products.length <= 0) {
    if (recommendation?.type == "sameEanOrSeoId" && recommendation.product) {
      products = [recommendation.product];
    } else {
      return null;
    }
  }

  if (recommendation?.type == "ids") {
    const missingIds = recommendation.ids.filter((id) => !products.find((p) => p.id == id));
    if (missingIds && missingIds.length > 0) {
      const msg = `Missing products in ${router.asPath}: ${missingIds.join(", ")}`;
      // eslint-disable-next-line no-console
      console.error(msg);
    }
  }

  return getOutput(
    classes,
    matchesXS,
    products,
    highlightsClass,
    horizontalScrollClassName,
    title,
    verticalScroll,
    productsPostprocessor,
    anchor,
    align,
    itemClass,
    getTitle,
    getSubTitle,
    forceLoad,
    typographyVariant,
    cardType
  );
};

export default ProductHightlights;
