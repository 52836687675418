import Button from "@material-ui/core/Button";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    textDecoration: "none",
    "& .MuiButton-root": {
      marginTop: theme.spacing(2),
      color: theme.palette.background.secondary,
      backgroundColor: theme.openButtonColor,
    },
  },
}));

const ButtonLinkExternal = (props) => {
  const { href, variant, color, endIcon, style } = props;
  const classes = useStyles();

  return (
    <a
      target="_blank"
      rel="noopener nofollow external"
      href={href}
      className={`FX-ButtonExternalLink ${classes.button}`}
    >
      <Button component="span" variant={variant} color={color} endIcon={endIcon} style={style}>
        {props.children ? props.children : undefined}
      </Button>
    </a>
  );
};

export default ButtonLinkExternal;
