import BackIcon from "./Icons/BackIcon";
import LocaleLink from "src/components/Navigation/LocaleLink";
import OpenIcon from "./Icons/OpenIcon";
import React from "react";
import { TheCycleverseTheme } from "src/theme";
import { UrlObject } from "url";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  text: {
    display: "inline-block",
    paddingTop: 10,
  },
  prev: {
    display: "inline-block",
    width: 40,
    height: 40,
    padding: 10,
    "& .MuiIcon-root": {
      height: 20,
      width: 20,
    },
  },
  next: {
    display: "inline-block",
    width: 40,
    height: 40,
    padding: 10,
    "& .MuiIcon-root": {
      height: 20,
      width: 20,
    },
  },
}));

type PaginationButtonProps = {
  total: number;
  current: number;
  onNext?: any;
  onPrev?: any;
  className?: string;
  useHref?: boolean;
  nextLink: string | UrlObject;
  prevLink: string | UrlObject;
};

const PaginationButton = (props: PaginationButtonProps) => {
  const { total, current, onNext, onPrev, className, useHref, nextLink, prevLink } = props;
  const { t } = useTranslation("common");
  const classes = useStyles();

  const getJSButtons = () => {
    return (
      <React.Fragment>
        {current > 1 ? (
          <div className={classes.prev} onClick={() => onPrev()}>
            <BackIcon />
          </div>
        ) : (
          <div className={classes.prev} />
        )}
        <div className={classes.text}>{t("paginationText", { current, total })}</div>
        {current < total ? (
          <div className={classes.next} onClick={() => onNext()}>
            <OpenIcon />
          </div>
        ) : (
          <div className={classes.next} />
        )}
      </React.Fragment>
    );
  };

  const getHrefButtons = () => {
    return (
      <React.Fragment>
        {current > 1 ? (
          <LocaleLink href={prevLink} shallow={true} onClick={() => onPrev && onPrev()}>
            <a className={classes.prev} rel="prev">
              <BackIcon />
            </a>
          </LocaleLink>
        ) : (
          <div className={classes.prev} />
        )}
        <div className={classes.text}>{t("paginationText", { current, total })}</div>
        {current < total ? (
          <LocaleLink href={nextLink} shallow={true} onClick={() => onNext && onNext()}>
            <a className={classes.next} rel="next">
              <OpenIcon />
            </a>
          </LocaleLink>
        ) : (
          <div className={classes.next} />
        )}
      </React.Fragment>
    );
  };

  return (
    <div className={`${classes.pagination} ${className ? className : ""}`}>
      {useHref ? getHrefButtons() : getJSButtons()}
    </div>
  );
};

export default PaginationButton;
