import Description, { DescriptionParagraph, DescriptionSection } from "../Description";
import React, { useContext, useEffect } from "react";
import parse, { attributesToProps, domToReact } from "html-react-parser";

import ArticleHighlights from "../Article/ArticleHighlights";
import AuthContext from "../../Auth/AuthContext";
import ButtonLink from "../../Navigation/ButtonLink";
import ButtonLinkApp from "../../Navigation/ButtonLinkApp";
import ButtonLinkExternal from "../../Navigation/ButtonLinkExternal";
import Cache from "../../../lib/cache";
import LazyLoad from "../../../lib/LazyLoad";
import ProductHightlights from "../Product/ProductHightlights";
import { TheCycleverseTheme } from "src/theme";
import TrustindexSnippet from "src/components/Social/TrustindexSnippet";
import clsx from "clsx";
import environment from "src/environment";
import { getText } from "../../../graphql/queries";
import { makeStyles } from "@material-ui/core/styles";
import { default as productCategories } from "src/categoriesExplodedTree.json";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useSWR from "swr";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  newlineParagraphs: {
    whiteSpace: "pre-line",
  },
}));

const Text = (props) => {
  const {
    prefetchedContent,
    noFetch,
    className,
    id,
    hideEditButton,
    useCache,
    anchorId,
    fallback,
    nameSuffix,
    buttonText,
    titleClass,
    cardContainerClass,
    isContentSet,
    isInOverflowContainer,
    disableImageLazyLoad,
  } = props;
  const {
    auth: { isAdmin },
  } = useContext(AuthContext);
  const { t } = useTranslation("common");
  const classes = useStyles();
  const editButtonCaption = buttonText ? buttonText : `${t("textEdit") + (nameSuffix || "")}`;
  const isMobile = useMediaQuery("(max-width: 600px)");
  const shouldFetch = isAdmin || !noFetch;

  const { data } = useSWR(
    shouldFetch
      ? [
          getText,
          JSON.stringify({
            id,
          }),
        ]
      : null
  );

  let content = prefetchedContent ? prefetchedContent : data?.getText?.content;

  useEffect(() => {
    if (isContentSet) {
      isContentSet(!!content);
    }
  }, [content, isContentSet]);

  if (!content) {
    return (
      <div id={anchorId} className={clsx([classes.newlineParagraphs, className])}>
        {isAdmin && !hideEditButton && (
          <div>
            <ButtonLink style={{ backgroundColor: "yellow" }} href={`/admin/text/[id]`} as={`/admin/text/${id}`}>
              {editButtonCaption}
            </ButtonLink>
          </div>
        )}
        {fallback}
      </div>
    );
  }

  const parseOptions = (isInOverflowContainer: boolean): any => {
    return {
      replace: (domNode) => {
        if (domNode.type == "tag" && domNode.name == "img") {
          let mobileImage: string;
          let desktopImage: string;
          if (domNode.attribs.title?.toLowerCase() == "mobile") {
            mobileImage = domNode.attribs.src;
          } else {
            desktopImage = domNode.attribs.src;
          }
          if (isMobile) {
            return (
              <LazyLoad forceLoad={disableImageLazyLoad} placeholder={<span>{domNode.attribs.alt}</span>}>
                {mobileImage ? (
                  <img loading="lazy" src={mobileImage} alt={domNode.attribs.alt} />
                ) : (
                  <img loading="lazy" src={desktopImage} alt={domNode.attribs.alt} />
                )}
              </LazyLoad>
            );
          } else {
            return (
              <LazyLoad forceLoad={disableImageLazyLoad} placeholder={<span>{domNode.attribs.alt}</span>}>
                {desktopImage ? <img loading="lazy" src={desktopImage} alt={domNode.attribs.alt} /> : <></>}
              </LazyLoad>
            );
          }
        }

        const { attribs, children } = domNode;

        if (!attribs) {
          return;
        }

        if (domNode.type == "tag" && domNode.name == "table" && domNode.attribs.style) {
          const isTableWithFixedSize = domNode.attribs.style.indexOf("width: 100%;") < 0;
          const { attribs, children } = domNode;
          const props = attributesToProps(attribs ? attribs : {});

          if (isTableWithFixedSize) {
            return (
              <div className="FX-Article-TableWrapper-custom-width">
                <table {...props}>{domToReact(children, parseOptions(isInOverflowContainer))}</table>
              </div>
            );
          } else {
            return (
              <div style={{ overflowX: "auto" }}>
                <table {...props}>{domToReact(children, parseOptions(isInOverflowContainer))}</table>
              </div>
            );
          }
        }

        if (attribs && attribs.id === "products" && children && children[0] && children[0].data) {
          const idString = children[0].data;
          const productIds = idString.split(",").map((item) => item.trim());
          return <ProductHightlights recommendation={{ type: "ids", ids: productIds }} />;
        }

        if (attribs.class === "description-paragraph") {
          const reactChildren = domToReact(children, {});
          return <DescriptionParagraph parsedClass="description-paragraph">{reactChildren}</DescriptionParagraph>;
        } else if (attribs.class === "description-section") {
          const reactChildren = domToReact(children, parseOptions(isInOverflowContainer));
          const paragraphs = Array.isArray(reactChildren)
            ? reactChildren.filter((c) => c.props?.parsedClass === "description-paragraph")
            : reactChildren;
          const titleChildren = Array.isArray(reactChildren)
            ? reactChildren.filter((c) => c.props?.className === "description-section-title")
            : reactChildren;
          const title = titleChildren[0];
          return (
            <DescriptionSection parsedClass="description-section" title={title}>
              {paragraphs}
            </DescriptionSection>
          );
        } else if (attribs.class === "description") {
          const reactChildren = domToReact(children, parseOptions(isInOverflowContainer));
          const parsedChildren = Array.isArray(reactChildren)
            ? reactChildren.filter((c) => c.props?.parsedClass === "description-section")
            : reactChildren;
          return <Description>{parsedChildren}</Description>;
        }

        if (domNode.attribs && domNode.attribs.component === "ButtonLinkApp") {
          const ios = domNode.attribs.ios;
          const android = domNode.attribs.android;
          return <ButtonLinkApp ios={ios} android={android} />;
        }

        if (domNode.attribs && domNode.attribs.component === "ProductHighlights") {
          const type = domNode.attribs.type;
          if (type == "topHitsByBrand") {
            const categoryUrlId = domNode.attribs.category;
            if (productCategories[categoryUrlId]) {
              const category = productCategories[categoryUrlId].path
                .split("|")
                .map((p) => p.split(":")[0])
                .join(" > ");
              const price = domNode.attribs.price;
              const brands = domNode.attribs.brands;
              const sort = domNode.attribs.sort;
              return (
                <LazyLoad offset={100} height={200} overflow={isInOverflowContainer} once>
                  <ProductHightlights
                    recommendation={{
                      type: type,
                      brands: brands ? brands.split(",") : [],
                      priceMin: price ? parseInt(price.split(",")[0]) : environment.products.minimumPrice,
                      priceMax: price ? parseInt(price.split(",")[1]) : environment.products.maximumPrice,
                      category: category,
                      sort: sort ? sort : "default",
                    }}
                  />
                </LazyLoad>
              );
            }
          } else if (type == "search") {
            const search = domNode.attribs.search;
            const categories = domNode.attribs.categories;
            const price = domNode.attribs.price;
            const brands = domNode.attribs.brands;
            const sort = domNode.attribs.sort;
            return (
              <LazyLoad offset={100} height={200} overflow={isInOverflowContainer} once>
                <ProductHightlights
                  recommendation={{
                    type: type,
                    searchString: search || "",
                    brands: brands ? brands.split(",") : [],
                    priceMin: price ? parseInt(price.split(",")[0]) : environment.products.minimumPrice,
                    priceMax: price ? parseInt(price.split(",")[1]) : environment.products.maximumPrice,
                    categories: categories ? categories.split(",") : [],
                    sort: sort ? sort : "default",
                  }}
                />
              </LazyLoad>
            );
          }
        }

        if (domNode.attribs && domNode.attribs.component === "ArticleHighlights") {
          return (
            <ArticleHighlights
              cardContainerClass={cardContainerClass}
              titleClass={titleClass}
              articlesIds={domNode.attribs.ids}
            />
          );
        }

        if (domNode.attribs && domNode.attribs.component === "ButtonLinkExternal") {
          const href = domNode.attribs.href;
          const data = domNode.children && domNode.children.length >= 1 ? domNode.children[0].data : null;
          return <ButtonLinkExternal href={href}>{data}</ButtonLinkExternal>;
        }

        if (domNode.attribs && domNode.attribs.component === "TrustindexSnippet") {
          return (
            <LazyLoad offset={100} height={200} once>
              <TrustindexSnippet id={domNode.attribs.id} />
            </LazyLoad>
          );
        }
      },
    };
  };

  let parsed = useCache ? Cache.get(id) : null;
  if (!parsed) {
    parsed = parse(content, parseOptions(isInOverflowContainer));
    useCache && Cache.set(id, parsed);
  }

  return (
    <div id={anchorId} className={`${className ? className : ""}`}>
      <div className="FX-Text-content">
        {parsed}
        {parsed && props.children}
      </div>
      {isAdmin && !hideEditButton && (
        <ButtonLink
          style={{ backgroundColor: "yellow", marginTop: 20 }}
          href={`/admin/text/[id]`}
          as={`/admin/text/${id}`}
        >
          {editButtonCaption}
        </ButtonLink>
      )}
    </div>
  );
};

export default Text;
