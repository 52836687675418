import Button from "@material-ui/core/Button";
import LocaleLink from "./LocaleLink";
import React from "react";

const ButtonLink = (props) => {
  const { as, href, variant, color, target, buttonClassName, aClassName, endIcon, style, dataTest, prefetch } = props;

  return (
    <LocaleLink notStyled aClass={aClassName} target={target} href={href} as={as} prefetch={prefetch}>
      <Button
        component="div"
        variant={variant}
        color={color}
        className={buttonClassName}
        endIcon={endIcon}
        style={style}
        data-test={dataTest ? dataTest : ""}
      >
        {props.children ? props.children : undefined}
      </Button>
    </LocaleLink>
  );
};

export default ButtonLink;
