export const seoFriendlyId = (value) => {
  return value
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/,/g, "-")
    .replace(/ü/g, "ue")
    .replace(/ö/g, "oe")
    .replace(/ä/g, "ae")
    .replace(/ß/g, "ss")
    .replace(/\//g, "-")
    .replace(/&/g, "-")
    .replace(/[^\w|^-]/g, "")
    .replace(/-{2,}/g, "-");
};
