import React, { FC } from "react";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import LazyLoad from "../../lib/LazyLoad";
import LocaleLink from "../Navigation/LocaleLink";
import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  card: {
    backgroundColor: theme.palette.background.default,
    border: "none",
    position: "relative",
  },
  media: {
    position: "relative",
    paddingTop: "100%",
    backgroundSize: "contain",
    backgroundColor: theme.product.imageBackground,
    // margin: theme.cardSpacing,
    marginBottom: 0,
  },
  cardContent: {
    padding: theme.cardSpacing,
    paddingTop: 0,
  },
  cardContentOverlay: {
    position: "absolute",
    bottom: 0,
  },
  mediaContent: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
  },
}));

export type GenericCardProps = {
  className?: string;
  data: {
    localeLinkHref: string;
    localeLinkAs: string;
    imageLarge: string;
    imageSmall: string;
    imageAlt: string;
  };
  overlayContent?: any;
  coverImageArea?: any;
  cardMediaContent?: any;
  forceLoad?: boolean;
};

const GenericCard: FC<GenericCardProps> = (props) => {
  const { className, data, overlayContent, coverImageArea, cardMediaContent, forceLoad } = props;
  const classes = useStyles();
  const isDesktopView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.up("sm"));

  return (
    <Card variant="outlined" className={`${classes.card} ${className ? className : ""}`}>
      <LocaleLink notStyled href={data.localeLinkHref} as={data.localeLinkAs}>
        <div>
          <CardActionArea disableRipple={true} disableTouchRipple={true} focusRipple={false}>
            <LazyLoad forceLoad={forceLoad}>
              <CardMedia
                className={`${classes.media} FX-GenericCard-Media`}
                style={coverImageArea ? { backgroundSize: "cover" } : {}}
                image={isDesktopView ? data.imageLarge : data.imageSmall}
                title={data.imageAlt}
              >
                {cardMediaContent && <div className={classes.mediaContent}>{cardMediaContent}</div>}
              </CardMedia>
            </LazyLoad>
            <CardContent
              className={`${classes.cardContent} ${
                overlayContent ? classes.cardContentOverlay : ""
              } FX-GenericCard-Content`}
            >
              {props.children}
            </CardContent>
          </CardActionArea>
        </div>
      </LocaleLink>
    </Card>
  );
};

export default GenericCard;
