import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import GenericCard from "./GenericCard";

const useStyles = makeStyles((theme) => ({
  toolMediaContent: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    "& div": {
      marginTop: 20,
      marginBottom: 30,
      fontSize: 16,
      fontWeight: 500,
      color: theme.likeColor,
      maxWidth: 120,
      textAlign: "center",
    },
  },
}));

const ToolCard = (props) => {
  const { data, className, forceLoad } = props;
  const classes = useStyles();

  const cardData = {
    ...data,
  };

  const { imageUrl, imageText } = data;
  const cardMediaContent = (
    <div className={classes.toolMediaContent}>
      <img loading="lazy" src={imageUrl} height={70} width={70} alt={imageText} />
      <div>{data.imageText}</div>
    </div>
  );

  return (
    <GenericCard data={cardData} className={className} cardMediaContent={cardMediaContent} forceLoad={forceLoad}>
      <div>{data.text}</div>
    </GenericCard>
  );
};

export default ToolCard;
