import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import { useAsync } from "react-use";
import Analytics from "../../../lib/analytics/analytics";
import AuthContext from "../../Auth/AuthContext";
import BikeCompareButtonAdd from "../../BikeCompare/BikeCompareButtonAdd";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import GqlClientContext from "../../../lib/gqlClientContext";
import Grid from "@material-ui/core/Grid";
import LazyLoad from "../../../lib/LazyLoad";
import PriceDiscountTag from "./PriceDiscountTag";
import PriceTag from "./PriceTag";
import ProductLike from "./ProductLike";
import ProductOfferCount from "./ProductOfferCount";
import RefurbishedTag from "./RefurbishedTag";
import { TheCycleverseTheme } from "src/theme";
import Typography from "@material-ui/core/Typography";
import dynamic from "next/dynamic";
import environment from "../../../environment";
import { getProductInfoLink, getProductLink } from "./useProductLink";
import { makeStyles } from "@material-ui/core/styles";
import { updateProduct } from "../../../graphql/mutations";
import { useMeasure } from "react-use";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTranslation from "next-translate/useTranslation";
import useTranslationExtras from "../../../translations/useTranslationExtras";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  root: {
    flexGrow: 1,
  },
  typography: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  media: {
    position: "relative",
    margin: theme.cardSpacing,
    marginBottom: 0,
    paddingTop: "100%",
    backgroundSize: "contain",
    backgroundColor: theme.product.imageBackground,
    [theme.breakpoints.down("xs")]: {
      paddingTop: "125%",
    },
  },
  brand: {
    color: theme.hightlightColor,
    lineHeight: "1.3",
  },
  category: {
    fontSize: "small",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflowX: "hidden",
  },
  deliveryCosts: {
    fontSize: "small",
    color: theme.unflashyColor,
  },
  mwst: {
    fontSize: 8,
    color: theme.unflashyColor,
  },
  title: {
    paddingTop: theme.spacing(1),
    fontSize: "small",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflowX: "hidden",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  deleteProductButton: {
    backgroundColor: "red",
  },
  moveProductButton: {
    marginLeft: 5,
    backgroundColor: "yellow",
  },
  card: {
    backgroundColor: theme.product.imageBackground,
    border: "none",
    marginTop: theme.cardSpacing,
    position: "relative",
    overflow: "unset",
  },
  cardContent: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: theme.cardSpacing,
  },
  likes: {
    position: "absolute",
    bottom: theme.cardSpacing,
    right: theme.cardSpacing,
    zIndex: 1,
    margin: theme.cardButtonSpacing,
  },
  salePercentage3: {
    zIndex: 1,
    position: "absolute",
    top: theme.cardSpacing,
    left: theme.cardSpacing,
    margin: theme.cardButtonSpacing,
    lineHeight: "20px",
  },
  refurbishedTag: {
    position: "absolute",
    top: theme.cardSpacing + 32,
    left: theme.cardSpacing,
    margin: theme.cardButtonSpacing,
  },
  refurbishedTagAlt: {
    position: "absolute",
    margin: theme.cardButtonSpacing,
    top: theme.cardSpacing,
    left: "50%",
    transform: "translate(-50%, 0%)",
  },
  bikeCompareButtonAdd: {
    position: "absolute",
    top: theme.cardSpacing,
    right: theme.cardSpacing,
    margin: theme.cardButtonSpacing,
  },
  productOfferCount: {
    position: "absolute",
    bottom: theme.cardSpacing,
    left: theme.cardSpacing,
    margin: theme.cardButtonSpacing,
  },
}));

const getCategoryDisplayName = (category) => {
  if (!category) return category;
  const parts = category.split(">");
  const lastPart = parts[parts.length - 1];
  return lastPart.trim();
};

const getImageUrl = (product, isDesktopView) => {
  let result;
  if (isDesktopView) {
    if (product.renditionLarge) {
      result = product.renditionLarge;
    } else if (product.images) {
      result = product.images[0].uri;
    }
  } else {
    if (product.renditionMedium) {
      result = product.renditionMedium;
    } else if (product.imagesSmall && product.imagesSmall[0]) {
      result = product.imagesSmall[0].uri;
    } else if (product.images) {
      result = product.images[0].uri;
    }
  }
  return result;
};

const isComparableBike = (category) => {
  return (
    category.indexOf("Fahrräder >") == 0 ||
    (category.indexOf("E-Bikes >") == 0 && category.indexOf("E-Bikes > E-Bike Teile") < 0)
  );
};

const getComparableBikeFlavor = (category) => {
  if (category.indexOf("Fahrräder >") == 0) {
    return "biobike";
  } else if (category.indexOf("E-Bikes >") == 0) {
    return "ebike";
  }
};

const MoveButton = (props) => {
  const { product } = props;
  const classes = useStyles();
  const [categorySelectOpen, setCategorySelectOpen] = React.useState(false);
  const gqlFetcher = useContext(GqlClientContext);
  const { t } = useTranslation("common");

  const CategorySelect = dynamic(() => import("../../Categories/CategorySelect"), {
    ssr: false,
  });
  const handleDeleteProduct = (id) => {
    const confirmed = confirm("Product won't be visible anymore! Please refresh page after deletion");
    if (confirmed == true) {
      gqlFetcher([updateProduct, JSON.stringify({ input: { id: id, visibility: "hidden" } })]);
    }
  };

  return (
    <div>
      <Button className={classes.deleteProductButton} onClick={() => handleDeleteProduct(product.id)}>
        {t("productsDelete")}
      </Button>
      <Button className={classes.moveProductButton} onClick={() => setCategorySelectOpen(true)}>
        {t("productsMove")}
      </Button>
      <CategorySelect
        open={categorySelectOpen}
        onClose={() => setCategorySelectOpen(false)}
        onSelect={(category) => {
          const confirmed = confirm("Product will be moved to category: " + category);
          if (confirmed == true) {
            gqlFetcher([
              updateProduct,
              JSON.stringify({
                input: {
                  id: product.id,
                  category: category,
                  categoryLock: category,
                },
              }),
            ]);
          }
        }}
      />
    </div>
  );
};

const ProductCard = (props) => {
  const { product, forceLoad, hideDetails, forceShowBikeCompare, bikeCompareFlavor, omitDetailPage } = props;
  const classes = useStyles();
  const {
    auth: { isAdmin },
  } = useContext(AuthContext);
  const { t, lang: locale } = useTranslation("common");
  const { priceAsFloat, priceAsString } = useTranslationExtras(t, locale);
  const isDesktopView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.up("sm"));
  const [showAlternateRefurbishedTag, setShowAlternateRefurbishedTag] = useState(false);

  const ref = useRef(null);
  useEffect(() => {
    const width = ref.current ? ref.current.offsetWidth : 0;
    setShowAlternateRefurbishedTag(width > 250); // measures only once on load. would be better to have clean css but I couldn't figure it out in time. techdebt
  }, [ref, setShowAlternateRefurbishedTag]);

  const userId = useAsync(async () => {
    return await Analytics.getUserId();
  }, []);

  const href =
    omitDetailPage && !userId.loading && userId.value
      ? getProductLink(product, userId.value)
      : getProductInfoLink(product, locale);

  const handleProductClick = useCallback(
    (product) => {
      Analytics.productClick(product.id, product.seoId, product.title, {
        advertiser: product.advertiser,
        category: product.category,
        priceAmount: priceAsFloat(product.price),
        priceCurrency: product.price.currency,
      });
    },
    [priceAsFloat]
  );

  return (
    <Card variant="outlined" className={classes.card}>
      <a
        className={classes.link}
        href={href}
        target={"_blank"}
        hrefLang={product.locale}
        onClick={() => handleProductClick(product)}
        data-test="productCard-link"
      >
        <div ref={ref}>
          <CardActionArea disableRipple={true} disableTouchRipple={true} focusRipple={false} component="div">
            <LazyLoad forceLoad={forceLoad} once>
              <CardMedia className={classes.media} image={getImageUrl(product, isDesktopView)} title={product.title}>
                {product.productCondition == "refurbished" && (
                  <RefurbishedTag
                    className={showAlternateRefurbishedTag ? classes.refurbishedTagAlt : classes.refurbishedTag}
                    variant={showAlternateRefurbishedTag ? "standard" : "negative"}
                  />
                )}
                <PriceDiscountTag className={classes.salePercentage3} price={product.price} />
                <ProductLike className={classes.likes} productId={product.id} count={product.likes} />
                {environment.features.enableBikeCompare &&
                  (isComparableBike(product.category) || forceShowBikeCompare) && (
                    <BikeCompareButtonAdd
                      seoId={product.seoId}
                      className={classes.bikeCompareButtonAdd}
                      flavor={bikeCompareFlavor || getComparableBikeFlavor(product.category)}
                    />
                  )}
                <ProductOfferCount product={product} className={classes.productOfferCount} />
              </CardMedia>
            </LazyLoad>
            {(typeof hideDetails === "undefined" || !hideDetails) && (
              <CardContent className={`${classes.cardContent} FX-ProductCard-content`}>
                <Grid container>
                  <Grid item xs={12}>
                    <PriceTag price={product.price}></PriceTag>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.brand}>{product.brand}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={`${classes.title} ${classes.typography}`}>{product.title}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.category}>{getCategoryDisplayName(product.category)}</Typography>
                  </Grid>
                  {product.deliveryCost && (
                    <Grid item xs={12}>
                      <Typography className={classes.deliveryCosts}>
                        {`${t("productsDelivery")} ${priceAsString(product.deliveryCost)}`}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography className={classes.mwst}>{t("productsTax")}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            )}
          </CardActionArea>
        </div>
      </a>
      {isAdmin && (
        <Grid item xs={12}>
          <Typography>{product.id}</Typography>
        </Grid>
      )}
      {isAdmin && <MoveButton product={product} />}
    </Card>
  );
};

export default ProductCard;
