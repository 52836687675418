import React, { useEffect, useRef, useState } from "react";

import Grid from "@material-ui/core/Grid";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme) => ({
  headline: {
    ...theme.headline1,
  },
  highlightsMobile: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  gridList: () => ({
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    width: "100%",
    "& .MuiImageListItem-item": {
      padding: `${theme.cardSpacing}px!important`,
    },
    justifyContent: "left",
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(2),
      "&::-webkit-scrollbar": {
        width: 5,
        height: 5,
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.unflashyColor,
        borderRadius: 10,
      },
      "&::-webkit-scrollbar-track": {
        background: theme.unflashyerColor,
        marginLeft: "25vh",
        marginRight: "25vh",
      },
    },
  }),
  spotlightCards: {
    marginBottom: theme.spacing(2),
    "& .MuiGrid-item": {
      paddingRight: theme.cardSpacing,
    },
  },
  disabled: {
    pointerEvents: "none",
  },
  moreContainer: {
    width: "100%",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.globalMarginMobile,
    },
  },
  moreButton: {
    cursor: "pointer",
    fontSize: 16,
    lineHeight: "20px",
  },
  moreButtonIcon: {
    float: "right",
    marginLeft: 5,
    display: "block",
    height: 20,
    width: 20,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundImage: "url('/images/Mehr-Pfeil.svg')",
  },
}));

const CardHighlightsHorizontal = (props) => {
  const { title, cards, spotlightCount, className, horizontalScrollClassName, largeCardOnDesktop } = props;
  const isDesktopView = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const classes = useStyles();
  const spotlightCards = cards.slice(0, spotlightCount ? spotlightCount : 0);
  const listCards = cards.slice(spotlightCount ? spotlightCount : 0);
  const cols = isDesktopView ? 4.5 : 1.5;
  const [disabled, setDisabled] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [scroll, setScroll] = useState({ scrollLeft: 0, clientX: 0 });
  const scroller = useRef(null);
  const scrollMoreStep = 300;
  const { t } = useTranslation("common");

  const onMouseMove = (event) => {
    setDisabled(true);
    const { clientX, scrollLeft } = scroll;
    const newScroll = scrollLeft + clientX - event.clientX;
    scroller.current.scrollLeft = newScroll;
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", onMouseMove);
    }

    return function cleanup() {
      window.removeEventListener("mousemove", onMouseMove);
    };
  });

  const onMouseDown = (event) => {
    if (isDesktopView) {
      const { scrollLeft } = scroller.current;
      setScroll({ scrollLeft, clientX: event.clientX });
      setIsDragging(true);
    }
  };

  const onMouseUp = () => {
    if (isDesktopView) {
      setIsDragging(false);
      setDisabled(false);
      setScroll({ scrollLeft: 0, clientX: 0 });
    }
  };

  // const checkShowMore = () => {
  //   const maxScrollLeft = scroller.current.scrollWidth - scroller.current.clientWidth;
  //   const currentScrollLeft = scroller.current.scrollLeft;
  //   const hasMoreContent = currentScrollLeft + scrollMoreStep < maxScrollLeft;
  // }

  const showMore = () => {
    const { scrollLeft } = scroller.current;
    const newScroll = scrollLeft + scrollMoreStep;
    const smoothscroll = (start, end) => {
      const stepsize = 40;
      const interval = 20;
      setTimeout(function() {
        if (start + stepsize >= end) {
          scroller.current.scrollLeft = end;
        } else {
          scroller.current.scrollLeft = start + stepsize;
          smoothscroll(start + stepsize, end);
        }
      }, interval);
    };
    smoothscroll(scroller.current.scrollLeft, newScroll);
    setScroll({ scrollLeft: 0, clientX: 0 });
  };

  return (
    <div className={className ? className : ""}>
      {title && (
        <Typography variant="h6" component="h2" className={`${classes.headline} FX-CardHighlightsHorizontal-title`}>
          {title}
        </Typography>
      )}

      {spotlightCards && spotlightCards.length > 0 && (
        <Grid container spacing={2} className={classes.spotlightCards}>
          {spotlightCards.map((card, i) => (
            <Grid key={i} item xs={12} sm={6} md={4}>
              {card}
            </Grid>
          ))}
        </Grid>
      )}

      <div
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        className={`${classes.highlightsMobile} ${horizontalScrollClassName ? horizontalScrollClassName : ""}`}
      >
        {(listCards.length > 4 ||
          (listCards.length > 1 && !isDesktopView) ||
          (largeCardOnDesktop && listCards.length > 2 && isDesktopView)) && (
          <div className={classes.moreContainer}>
            <div className={classes.moreButton} onClick={() => showMore()}>
              {t("cardHighLightsMore")}
              <i className={classes.moreButtonIcon} />
            </div>
          </div>
        )}
        <ImageList
          ref={scroller}
          className={`${classes.gridList} ${disabled ? classes.disabled : ""}`}
          gap={0}
          cols={cols}
          rows={1}
          rowHeight={"auto"}
        >
          {listCards.map((card, i) => (
            <ImageListItem cols={largeCardOnDesktop && isDesktopView ? 2 : 1} key={i}>
              {card}
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </div>
  );
};

export default CardHighlightsHorizontal;
