import React, { FC } from "react";

import Analytics from "../../../lib/analytics/analytics";
import ArticleCategory from "./ArticleCategory";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import LazyLoad from "../../../lib/LazyLoad";
import LocaleLink from "../../Navigation/LocaleLink";
import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  root: {
    // flexGrow: 1,
    width: 400,
  },
  card: {
    backgroundColor: theme.palette.background.secondary,
    border: "none",
    width: "100%",
  },

  cardMinimal: {
    "& .FX-ArticleCard-Media": {
      paddingTop: "100%",
      backgroundSize: "cover",
    },
    "& .FX-ArticleCard-Title": {
      fontSize: 20,
      wordBreak: "break-word",
    },
  },
  media: {
    paddingTop: "69%",
    backgroundSize: "contain",
  },
  cardContent: {
    padding: 10,
  },
  title: {
    fontWeight: 900,
    lineHeight: "1.1",
    fontSize: 32,
    marginBottom: theme.spacing(1),
  },
  author: {
    fontSize: "small",
    marginBottom: theme.spacing(2),
  },
  authorPrefix: {
    whiteSpace: "pre-wrap",
  },
  authorName: {
    fontWeight: 600,
  },
  short: {
    fontSize: 17,
    marginBottom: theme.spacing(2),
  },
  cta: {},
  ctaButton: {
    border: "1px solid #000",
  },

  categorOnBrandDetailsPage: {
    fontFamily: theme.highlightFontFamily,
    fontWeight: 600,
    fontSize: 16,
  },
}));

const shortenText = (text, max) => {
  if (text.length < max) {
    return text;
  }
  const words = text.split(" ");
  let returnedWords = [];
  let charCount = 0;
  for (let i = 0; i < words.length; i++) {
    if (charCount >= max) {
      break;
    }
    const word = words[i];
    charCount += word.length;
    returnedWords.push(word);
  }
  return returnedWords.join(" ") + "...";
};

const ArticleCard: FC<{
  article: any;
  variant?: string;
  target?: "new";
  className?: string;
  forceLoad?: boolean;
  cardClass?: string;
  titleClass?: string;
  cardContainerClass?: string;
}> = ({ article, variant, target, className, forceLoad, cardClass, titleClass, cardContainerClass }) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const isDesktopView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.up("sm"));

  const handleProductClick = (article) => {
    Analytics.articleClick(article.id, {
      author: article.author,
      category: article.category,
    });
  };

  const getImageUri = (uri) => {
    if (isDesktopView) {
      return `${uri}`;
    } else {
      if (variant == "minimal") {
        return `${uri}-320`;
      }
      return `${uri}-400`;
    }
  };

  return (
    <Card
      variant="outlined"
      className={`${className ? className : ""} ${
        cardClass ? cardClass : cardContainerClass ? cardContainerClass : classes.card
      } ${variant == "minimal" ? classes.cardMinimal : ""}`}
    >
      <LocaleLink notStyled href="/blog/[generic]" as={`/blog/${article.id}`} target={target}>
        <div onClick={() => handleProductClick(article)} data-test="articleCard-link">
          <CardActionArea disableRipple={true}>
            <LazyLoad forceLoad={forceLoad}>
              <CardMedia
                className={`${classes.media} FX-ArticleCard-Media`}
                image={getImageUri(article.images[0].uri)}
                title={article.images[0].alt}
              />
            </LazyLoad>
            <CardContent className={classes.cardContent}>
              <Grid container>
                {article.visibility != "public" && (
                  <Grid item xs={12}>
                    <div
                      style={{
                        backgroundColor: "red",
                        padding: 5,
                        color: "white",
                        fontWeight: 900,
                        fontSize: "large",
                      }}
                    >
                      HIDDEN{" "}
                      <i style={{ fontSize: 12 }}>{`${new Date(article.releaseTimestamp * 1000).toLocaleDateString(
                        "de-DE"
                      )}`}</i>
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <ArticleCategory className={classes.categorOnBrandDetailsPage} category={article.category} />
                </Grid>
                <Grid item xs={12} className={titleClass ? titleClass : `${classes.title} FX-ArticleCard-Title`}>
                  {article.title}
                </Grid>

                {variant != "minimal" && (
                  <Grid item xs={12} className={classes.author}>
                    <span className={classes.authorPrefix}>{t("articleAuthorPrefix")}</span>
                    <span className={classes.authorName}>{article.author}</span>
                  </Grid>
                )}

                {variant != "minimal" && (
                  <Grid item xs={12} className={classes.short}>
                    {shortenText(article.short, 90)}
                  </Grid>
                )}

                {variant != "minimal" && (
                  <Grid item xs={12} className={classes.cta}>
                    <Button className={classes.ctaButton} component="div">
                      {t("articleReadMore")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </CardActionArea>
        </div>
      </LocaleLink>
    </Card>
  );
};

export default ArticleCard;
