import React, { FC } from "react";

type RefurbishedTagProps = {
  className?: string;
  variant?: "negative" | "standard";
};

const RefurbishedTag: FC<RefurbishedTagProps> = (props) => {
  const { className, variant } = props;
  return (
    <div className={className ? className : ""}>
      <img
        loading="lazy"
        src={variant == "negative" ? "/images/RefurbishedNeg.svg" : "/images/Refurbished.svg"}
        height={variant == "negative" ? 15 : 22}
        alt="Refurbished"
      />
    </div>
  );
};

export default RefurbishedTag;
