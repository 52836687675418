import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  salePercentage2: {
    color: theme.product.imageBackground,
    backgroundColor: theme.saleColor,
    padding: "1px 5px",
    borderRadius: theme.shape.borderRadius,
    fontSize: "small",
    fontWeight: 900,
  },
}));

const PriceDiscountTag = (props) => {
  const { price, className } = props;
  const classes = useStyles();

  if (!price.amountWithoutDiscount || price.amountWithoutDiscount <= price.amount) {
    return null;
  }

  return (
    <div className={`${classes.salePercentage2} ${className ? className : ""}`}>
      -{Math.max(1, 100 - Math.round((price.amount / price.amountWithoutDiscount) * 100))} %
    </div>
  );
};

export default PriceDiscountTag;
