import React, { useContext, useState } from "react";

import Analytics from "../../../lib/analytics/analytics";
import GqlClientContext from "../../../lib/gqlClientContext";
import { likeProduct } from "../../../graphql/queries";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  productLikeContainer: {
    padding: "20px 0px 0px 20px",
    cursor: "pointer",
  },
  productLike: {
    // backgroundColor: theme.palette.background.secondary,
    // padding: "2px 5px",
    // borderRadius: theme.shape.borderRadius
    display: "flex",
  },
  icon: {
    display: "inline-block",
    height: 14,
    width: 14,
    marginRight: 5,
    backgroundImage: "url(/images/Like-it.svg)",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  count: {
    display: "inline-block",
    color: theme.likeColor,
    fontSize: 13,
    fontWeight: 500,
    paddingTop: 1,
    verticalAlign: "bottom",
    lineHeight: "13px",
  },
}));

const alreadyLiked = (productId) => {
  const key = `product-like-${productId}`;
  if (typeof localStorage === "undefined") {
    // Guard for SSR
    return false;
  }
  return localStorage.getItem(key);
};

const likeGuard = (productId) => {
  if (typeof localStorage === "undefined") {
    // Guard for SSR
    return false;
  }
  if (!alreadyLiked(productId)) {
    const key = `product-like-${productId}`;
    localStorage.setItem(key, true);
    return true;
  }
  return false;
};

const like = async (productId, gqlFetcher) => {
  Analytics.productLike(productId);
  await gqlFetcher([likeProduct, JSON.stringify({ productId })]);
};

const ProductLike = (props) => {
  const { productId, count, className } = props;
  const [likeCount, setLikeCount] = useState((count ? count : 0) + (alreadyLiked(productId) ? 1 : 0));
  const classes = useStyles();
  const gqlFetcher = useContext(GqlClientContext);

  return (
    <div
      className={`${classes.productLikeContainer} ${className ? className : ""}`}
      onClick={async (e) => {
        e.preventDefault();
        const currentCount = likeCount;
        if (likeGuard(productId)) {
          like(productId, gqlFetcher);
          setLikeCount(currentCount + 1);
        }
      }}
    >
      <div className={classes.productLike}>
        <div className={classes.icon} />
        <div className={classes.count}>{likeCount}</div>
      </div>
    </div>
  );
};

export default ProductLike;
