import React, { FC } from "react";

import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";
import { queryProducts } from "../../../graphql/queries";
import useSWR from "swr";
import useTranslation from "next-translate/useTranslation";
import useTranslationExtras from "../../../translations/useTranslationExtras";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  root: {
    flexGrow: 1,
  },
  price: {
    fontWeight: 900,
    lineHeight: "1.3",
    fontSize: "medium",
    display: "inline-block",
    marginRight: theme.spacing(1),
  },
  priceWithoutDiscount: {
    textDecoration: "line-through",
  },
  priceWithDiscount: {
    color: theme.saleColor,
  },
}));

const isDiscount = (price) => {
  return "amountWithoutDiscount" in price && price.amountWithoutDiscount > price.amount;
};

const PriceTag: FC<{
  price: any;
  fetchOffersForProduct?: any;
  className?: string;
}> = ({ price, fetchOffersForProduct, className }) => {
  const classes = useStyles();
  const { t, lang } = useTranslation();
  const { priceAsString, priceWithoutDiscountAsString } = useTranslationExtras(t, lang);

  const { data } = useSWR(
    fetchOffersForProduct
      ? [
          queryProducts,
          JSON.stringify({
            action: "productOffers",
            ean: fetchOffersForProduct.ean,
            seoId: fetchOffersForProduct.seoId,
          }),
        ]
      : null
  );

  const items = data?.queryProducts?.items;
  const minPriceOffer =
    items && items.length > 1
      ? items.reduce(function(prev, curr) {
          return prev.price.amount < curr.price.amount ? prev : curr;
        })
      : price;

  const actualPrice = minPriceOffer?.price || price;

  return (
    <div className={className}>
      {isDiscount(actualPrice) && (
        <div className={`${classes.priceWithDiscount} ${classes.price} FX-PriceTag-price`}>
          {priceAsString(actualPrice)}
        </div>
      )}
      {isDiscount(actualPrice) && (
        <div className={`${classes.priceWithoutDiscount} ${classes.price} FX-PriceTag-price`}>
          {priceWithoutDiscountAsString(actualPrice)}
        </div>
      )}
      {!isDiscount(actualPrice) && (
        <div className={`${classes.price} FX-PriceTag-price`}>{priceAsString(actualPrice)}</div>
      )}
    </div>
  );
};

export default PriceTag;
