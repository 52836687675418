import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.highlightFontFamily,
    fontWeight: 600,
    fontSize: 20,
  },

  categorOnBrandDetailsPage: {
    fontFamily: theme.highlightFontFamily,
    fontWeight: 600,
    fontSize: 16,
  },
}));

const getCategoryColor = (category) => {
  switch (category) {
    case "tests":
      return "#0096FF";
    case "kaufberatung":
      return "#11d5c5";
    case "innovation":
      return "#9C00A2";
    case "tricks":
      return "#05B709";
    case "knowhow":
      return "#FF8A00";
    case "food":
      return "#0F7F00";
    case "interviews":
      return "#FF0000";
    default:
      return "inherit";
  }
};

const getCategoryName = (key, t) => {
  const translation = t("articleCategory_" + key.replace("-", "_"));
  return translation ? translation : key;
};

const ArticleCategory = (props) => {
  const { category, className } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <span
      className={className ? className : `${classes.root} FX-ArticleCategory-Name`}
      style={{ color: getCategoryColor(category) }}
      data-test="article-category-name"
    >
      {getCategoryName(category, t)}
    </span>
  );
};

export default ArticleCategory;
