import React from "react";
import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";
import { queryProducts } from "../../../graphql/queries";
import useSWR from "swr";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  productOfferCountContainer: {
    color: theme.hightlightColorTertiary,
    fontWeight: 500,
    lineHeight: "13px",
    fontSize: 13,
  },
}));

const ProductOfferCount = (props) => {
  const { product, className } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useSWR([
    queryProducts,
    JSON.stringify({
      action: "productOffers",
      ean: product.ean,
      seoId: product.seoId,
    }),
  ]);

  const itemCount = data?.queryProducts?.items?.length;

  if (!itemCount || itemCount <= 1) {
    return null;
  }

  return (
    <div className={`${classes.productOfferCountContainer} ${className ? className : ""}`}>
      {t("productOfferCount", { itemCount })}
    </div>
  );
};

export default ProductOfferCount;
