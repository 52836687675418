import ArticleCard from "./ArticleCard";
import CardHighlightsHorizontal from "../CardHighlightsHorizontal";
import React from "react";
import { TheCycleverseTheme } from "src/theme";
import { default as categoriesConfig } from "../../../categoriesArticles.json";
import { makeStyles } from "@material-ui/core/styles";
import { queryArticles } from "../../../graphql/queries";
import useSWR from "swr";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  articleCardTitle: {
    fontWeight: 900,
    lineHeight: "1.1",
    fontSize: 16,
    color: "#212121",
    marginBottom: theme.spacing(1),
  },
}));

const ArticleHighlights = (props) => {
  const {
    highlightsClass,
    horizontalScrollClassName,
    articlesIds,
    title,
    titleClass,
    cardContainerClass,
    largeCardOnDesktop,
  } = props;
  const { t, lang: locale } = useTranslation("common");
  const classes = useStyles();

  const searchProps = {
    action: "search",
    visibility: "public",
    categories: categoriesConfig.categoriesInBlog.join(","),
    locale,
    size: 10,
  };

  if (articlesIds) {
    delete searchProps.categories;
    delete searchProps.size;
    searchProps["idFilter"] = articlesIds;
    searchProps["size"] = articlesIds.length;
  }

  const { data } = useSWR([queryArticles, JSON.stringify(searchProps)]);

  if (!data?.queryArticles?.items) {
    return null;
  }

  const articles = data.queryArticles.items;

  if (articles.length <= 0) {
    return null;
  }

  if (articlesIds) {
    articles.sort((a, b) => articlesIds.indexOf(a.id) - articlesIds.indexOf(b.id));
  }

  return (
    <CardHighlightsHorizontal
      className={highlightsClass ? highlightsClass : ""}
      horizontalScrollClassName={horizontalScrollClassName}
      title={title}
      largeCardOnDesktop={largeCardOnDesktop}
      cards={articles.map((article) => (
        <ArticleCard
          key={article.id}
          article={article}
          variant="minimal"
          target="new"
          cardContainerClass={cardContainerClass}
          titleClass={titleClass ?? classes.articleCardTitle}
        />
      ))}
    />
  );
};

ArticleHighlights.defaultProps = {
  largeCardOnDesktop: true,
};

export default ArticleHighlights;
