import React from "react";
import { Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    backgroundImage: "url(/images/navmenu/Pfeil-rechts.svg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    display: "block",
  },
}));

const OpenIcon = (props) => {
  const { className } = props;
  const classes = useStyles();

  return <Icon className={`${classes.root} ${className ? className : ""}`} />;
};

export default OpenIcon;
