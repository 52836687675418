import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginRight: theme.spacing(1),
  },
}));

const ButtonLinkApp = (props) => {
  const { ios, android } = props;
  const classes = useStyles();

  return (
    <span>
      {android && (
        <a target="_blank" rel="nofollow" href={android}>
          <img
            loading="lazy"
            src="/images/playstore.png"
            width="140"
            height="70"
            alt="Google Playstore Image"
            className={classes.spacing}
          />
        </a>
      )}
      {ios && (
        <a target="_blank" rel="nofollow" href={ios}>
          <img
            loading="lazy"
            src="/images/appstore.png"
            width="147"
            height="70"
            alt="Apple AppStore Image"
            className={classes.spacing}
          />
        </a>
      )}
    </span>
  );
};

export default ButtonLinkApp;
