import React from "react";
import LazyLoad from "react-lazyload";

const LazyLoadWrapper = (props) => {
  const {
    forceLoad,
    children,
    once,
    height,
    offset,
    unmountIfInvisible,
    overflow,
    scrollContainer,
    placeholder,
  } = props;

  return React.Children.map(children, (child) => {
    if (forceLoad) {
      return child;
    } else {
      return (
        <LazyLoad
          height={height}
          offset={offset}
          once={once}
          unmountIfInvisible={unmountIfInvisible}
          overflow={overflow}
          scrollContainer={scrollContainer}
          placeholder={placeholder}
        >
          {child && child}
        </LazyLoad>
      );
    }
  });
};

export default LazyLoadWrapper;
