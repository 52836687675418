import React, { useContext } from "react";

import AuthContext from "../../Auth/AuthContext";
import Button from "@material-ui/core/Button";
import ExternalLinkIcon from "../../Navigation/Icons/ExternalLinkIcon";
import PriceDiscountTag from "./PriceDiscountTag";
import PriceTag from "./PriceTag";
import ProductHref from "./ProductHref";
import ProductLinkComponent from "./ProductLinkComponent";
import RefurbishedTag from "./RefurbishedTag";
import { TheCycleverseTheme } from "src/theme";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { seoFriendlyId } from "../../../lib/seofriendly";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTranslation from "next-translate/useTranslation";
import useTranslationExtras from "../../../translations/useTranslationExtras";

const refurbishedBreakpointPx = 750;

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  advertiserCard: {
    display: "inline-flex",
    textAlign: "left",
    justifyContent: "right",
    alignItems: "center",
    width: "100%",
  },
  imgContainer: {
    textAlign: "right",
    width: "50%",
    marginRight: theme.spacing(4),
    "& img": {
      height: 40,
      maxWidth: "100%",
    },
    "& div": {
      width: 120,
      display: "inline-block",
      textAlign: "center",
    },
  },
  deliveryCosts: {
    fontSize: "small",
    color: theme.unflashyColor,
  },
  openButton: {
    fontSize: "14px",
    width: "130px",
    padding: "6px",
    "& .MuiButton-endIcon": {
      marginLeft: 12,
    },
  },
  price: {
    marginBottom: theme.spacing(2),
    "& .FX-PriceTag-price": {
      fontSize: "1.25rem",
    },
  },
  salePercent: {
    display: "inline-block",
    marginBottom: theme.spacing(1),
    padding: "2px 5px",
  },
  contentContainer: {
    width: "50%",
  },
  tagsContainer: {
    display: "flex",
    columnGap: 10,
  },
}));

const AdvertiserCard = (props) => {
  const { className, product } = props;
  const classes = useStyles();
  const { t, lang } = useTranslation("common");
  const { priceAsString } = useTranslationExtras(t, lang);
  const {
    auth: { isAdmin },
  } = useContext(AuthContext);

  return (
    <div className={`${className ? className : ""} ${classes.advertiserCard}`}>
      <div className={classes.imgContainer}>
        <div>
          <img
            loading="lazy"
            src={`https://images.thecycleverse.com/${seoFriendlyId(product.advertiser)}-logo.png`}
            width={"100%"}
            height={95}
            alt={`${product.advertiser} Logo`}
          />
        </div>
      </div>

      <div className={classes.contentContainer}>
        <div className={classes.tagsContainer}>
          <PriceDiscountTag className={classes.salePercent} price={product.price} />
          {product.productCondition == "refurbished" && <RefurbishedTag variant={"standard"} />}
        </div>
        <div className={classes.price}>
          <PriceTag price={product.price}></PriceTag>
          {product.deliveryCost && (
            <Typography className={classes.deliveryCosts}>
              {`${t("productsDelivery")} ${priceAsString(product.deliveryCost)}`}
            </Typography>
          )}
        </div>
        <ProductLinkComponent
          buttonClassName={classes.openButton}
          product={product}
          text={t("productInfoLink")}
          endIcon={<ExternalLinkIcon />}
        />
        {isAdmin && <Typography>{product.id}</Typography>}
      </div>
    </div>
  );
};

export default AdvertiserCard;
