import React, { useEffect, useRef, useState } from "react";

import AdCard from "../AdCard";
import ArticleTeaser from "../Article/ArticleTeaser";
import Grid from "@material-ui/core/Grid";
import LazyLoad from "../../../lib/LazyLoad";
import PaginationButton from "../../Navigation/PaginationButton";
import ProductCard from "./ProductCard";
import { TheCycleverseTheme } from "src/theme";
import ToolCard from "../ToolCard";
import { UrlObject } from "url";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useRouter } from "next/router";
import { useTheme } from "@material-ui/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  root: {
    flexGrow: 1,
  },
  addProduct: {
    backgroundColor: "green",
    position: "fixed",
    zIndex: 1000,
  },
  loadMoreContainer: {
    textAlign: "center",
  },
  loadMoreButton: {
    display: "inline-block",
    marginTop: theme.spacing(4),
    backgroundColor: "#000",
    color: "#fff",
    marginBottom: theme.spacing(4),
    paddingLeft: theme.highlightButtonPadding,
    paddingRight: theme.highlightButtonPadding,
    "&:hover": {
      backgroundColor: "#666",
    },
  },
  pagination: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  articleTeaser: {
    marginTop: 5,
    "& .FX-ArticleCard-Title, .FX-ArticleCategory-Name": {
      fontSize: 16,
      lineHeight: "21px",
    },
  },
  toolCard: {
    marginTop: 5,
    [theme.breakpoints.down("xs")]: {
      "& .FX-GenericCard-Media": {
        paddingTop: "125%",
      },
    },
    "& .FX-GenericCard-Content": {
      marginTop: 5,
      fontSize: 16,
      lineHeight: "21px",
      fontWeight: 900,
    },
  },
}));

type ProductFeedProps = {
  products: any;
  total?: number;
  loadMore?: any;
  stepSize?: number;
  className?: string;
  searchId?: string;
  offset?: number;
  nextLink?: string | UrlObject;
  prevLink?: string | UrlObject;
  showBioBikeFinderCard?: boolean;
  showEBikeFinderCard?: boolean;
  showKidsBikeFinderCard?: boolean;
  showArticleTeaser?: boolean;
  omitDetailPage?: boolean;
  disableLazyLoad?: boolean;
  onNext?: () => {};
  onPrev?: () => {};
};

const ProductFeed = (props: ProductFeedProps) => {
  const classes = useStyles();
  const theme = useTheme<TheCycleverseTheme>();
  const { t } = useTranslation("common");
  const router = useRouter();
  const isDesktopView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.up("sm"));
  const {
    products,
    total,
    loadMore,
    stepSize = 40,
    className,
    searchId,
    offset = 0,
    nextLink,
    prevLink,
    showBioBikeFinderCard,
    showEBikeFinderCard,
    showKidsBikeFinderCard,
    showArticleTeaser,
    omitDetailPage,
    disableLazyLoad,
    onNext,
    onPrev,
  } = props;
  const [currentSearchId, setCurrentSearchId] = useState(searchId);
  const maxPerPage = stepSize;
  const pageCount = total ? Math.ceil(total / maxPerPage) : 0;
  const currentPage = offset / maxPerPage + 1;
  const el = useRef(null);
  const scrollTop = () => {
    var headerOffset = isDesktopView ? 0 : theme.searchBarHeight;
    var elementPosition = el.current.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
    });
  };

  useEffect(() => {
    if (searchId != currentSearchId) {
      setCurrentSearchId(searchId);
    }
  }, [currentSearchId, searchId]);

  const adSlotOffet = 20;
  const bottomCardOffset = 5; //make sure ad is not shown at last
  const ads = [
    {
      isAd: true,
      id: "1",
      link: "https://getcyclique.com",
      imgBg: "/images/ads/Cyclique-Fond.jpg",
      imgFg: "/images/ads/Cyclique-Content.png",
    },
    // {
    //   isAd: true,
    //   id: "2",
    //   link:
    //     "https://thecycleverse.com/de/e-trekking/geero-2-city-comfort-vinyl-52-fuer-koerpergroesse-160-cm-180-cm",
    //   imgBg: "/images/ads/Geero-Fond.jpg",
    //   imgFg: "/images/ads/Geero-Content.png"
    // },
    {
      isAd: true,
      id: "2",
      link:
        "https://www.amazon.de/stores/node/1714914031?_encoding=UTF8&field-lbr_brands_browse-bin=Esbit&ref_=bl_dp_s_web_1714914031",
      imgBg: "/images/ads/Esbit-Fond.jpg",
      imgFg: "/images/ads/Esbit-Content.png",
    },
    {
      isAd: true,
      id: "3",
      link: "https://mecklenburger-seen-runde.de/&utm_source=thecycleverse",
      imgBg: "/images/ads/MSR-Fond.jpg",
      imgFg: "/images/ads/MSR-Content.png",
    },
    {
      isAd: true,
      id: "4",
      link: "http://www.veloclassico.de/home/&utm_source=thecycleverse",
      imgBg: "/images/ads/VeloClassico-Fond.jpg",
      imgFg: "/images/ads/VeloClassico-Content.png",
    },
  ];

  const ebikeCategories = [
    "e-bikes",
    "e-trekking",
    "e-urban",
    "e-city",
    "e-road",
    "e-mtb-fully",
    "e-mtb-hardtail",
    "e-cross",
    "e-gravel",
    "e-lastenraeder",
    "e-cruiser",
    "e-faltraeder",
    "e-kinder-jugendraeder",
    "e-scooter",
    "s-pedelecs-45-km-h",
  ];

  // if (ebikeCategories.indexOf(router.asPath.slice(1)) >= 0) {
  //   ads[0] = {
  //     isAd: true,
  //     id: "1",
  //     link: "https://www.awin1.com/cread.php?s=3338359&v=36206&q=451832&r=681903",
  //     imgBg: "/images/ads/Upway-Fond-1.jpg",
  //     imgFg: "/images/ads/Upway-Content-1.png",
  //   };
  // }

  // if ("/e-urban" == router.asPath) {
  //   ads[0] = {
  //     isAd: true,
  //     id: "1",
  //     link: "https://bzenbikes.com/de/e-shop/?v=3a52f3c22ed6&utm_source=thecycleverse&medium=banner&v=5b79c40fa7c2",
  //     imgBg: "/images/ads/BZEN-Fond-3.jpg",
  //     imgFg: "/images/ads/BZEN-Content-4.png",
  //   };
  // }

  const getItems = (products) => {
    let result = products.slice(0, maxPerPage);
    const defaultInsertIndex = 3;

    const insertAtIndex = (index, obj) => {
      if (index < result.length) {
        result.splice(index, 0, obj);
      }
    };

    if (showBioBikeFinderCard) {
      insertAtIndex(defaultInsertIndex, {
        isToolCard: true,
        id: "bioBikeFinder",
        localeLinkHref: "/welches-fahrrad-passt-zu-mir",
        localeLinkAs: "/welches-fahrrad-passt-zu-mir",
        text: t("toolCardBioBikeFinderTxt"),
        imageText: t("toolCardBioBikeFinderImgTxt"),
        imageUrl: "https://images.thecycleverse.com/clara.svg",
      });
    } else if (showEBikeFinderCard) {
      insertAtIndex(defaultInsertIndex, {
        isToolCard: true,
        id: "eBikeFinder",
        localeLinkHref: "/welches-ebike-passt-zu-mir",
        localeLinkAs: "/welches-ebike-passt-zu-mir",
        text: t("toolCardEBikeFinderTxt"),
        imageText: t("toolCardEBikeFinderImgTxt"),
        imageUrl: "https://images.thecycleverse.com/navmenu/Icon-E-Clara.svg",
      });
    } else if (showKidsBikeFinderCard) {
      insertAtIndex(defaultInsertIndex, {
        isToolCard: true,
        id: "kidsFinder",
        localeLinkHref: "/kinderfahrrad-finder",
        localeLinkAs: "/kinderfahrrad-finder",
        text: t("toolKidsBikeFinderTxt"),
        imageText: t("toolKidsBikeFinderImgTxt"),
        imageUrl: "https://images.thecycleverse.com/clara.svg",
      });
    } else if (showArticleTeaser) {
      insertAtIndex(defaultInsertIndex, {
        isArticle: true,
        id: "articleCard",
      });
    }

    for (let i = 0; i < ads.length; i++) {
      // check length
      const insertIndex = (i + 1) * adSlotOffet + i;
      if (insertIndex >= result.length - bottomCardOffset) {
        break;
      }
      const adIdx = (currentPage - 1 + i) % ads.length;
      insertAtIndex(insertIndex, ads[adIdx]);
    }

    return result;
  };

  const displayedProducts = getItems(products);

  const renderItem = (item) => {
    if (item.isAd) {
      return <AdCard ad={item} forceLoad={disableLazyLoad} />;
    }

    if (item.isToolCard) {
      return <ToolCard data={item} className={classes.toolCard} forceLoad={disableLazyLoad} />;
    }

    if (item.isArticle) {
      return <ArticleTeaser className={classes.articleTeaser} forceLoad={disableLazyLoad} />;
    }

    return <ProductCard product={item} omitDetailPage={omitDetailPage} forceLoad={disableLazyLoad} />;
  };

  return (
    <div ref={el} className={`${classes.root} ${className ? className : ""}`}>
      <Grid container spacing={2}>
        {displayedProducts.map((item, i) => (
          <LazyLoad key={i} forceLoad={disableLazyLoad || (isDesktopView ? i < 20 : i < 4)} once={true}>
            <Grid item key={item.id} xs={item.isAd ? 12 : 6} sm={item.isAd ? 8 : 4} md={item.isAd ? 6 : 3}>
              {renderItem(item)}
            </Grid>
          </LazyLoad>
        ))}
      </Grid>
      {total >= maxPerPage ? (
        <div className={classes.loadMoreContainer}>
          <PaginationButton
            className={classes.pagination}
            current={currentPage}
            total={pageCount}
            useHref={true}
            nextLink={nextLink}
            prevLink={prevLink}
            onNext={onNext}
            onPrev={onPrev}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default ProductFeed;
