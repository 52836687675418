import { Base64 } from "js-base64";
import TrafficSource from "../../../lib/analytics/trafficSource";
import { default as categoriesMapping } from "../../../categoriesMapping.json";

export function productToReadableId(product) {
  const { id, title } = product;
  const token = Base64.encode(id);
  const readableTitle = title.replace(/[^\w\s]/gi, "").replace(/\s/gi, "-");
  return encodeURIComponent(`${readableTitle}-${token}`);
}

export function readableIdToProductId(readableId) {
  const idStr = decodeURIComponent(readableId);
  const parts = idStr.split("-");
  const token = parts[parts.length - 1];
  return Base64.decode(token);
}

export function getProductInfoLink(product: any, locale?: string) {
  const category = product.category;
  const localePrefix = locale? ("/"+locale):"";
  const categoryPath = categoriesMapping[category] ? categoriesMapping[category] : "p";
  let link = '';
  if((product.recordType === 'bikepointer' || product.visibility=='hidden_aggregated_to_bike') && product.bikeSlug)
      link = `${localePrefix}/${categoryPath}${product.bikeSlug}`;
  else if(product.recordType === 'bike' && !!product.slug)
    link = `${localePrefix}/${categoryPath}${product.slug}`;
  else
    link = `${localePrefix}/${categoryPath}/${product.seoId}`;
  return link;
}

export function getProductLink(product: any, userId?: string) {
  const { link } = product;
  let forwardLink = link;
  const trafficSource = TrafficSource.getTrafficSource();
  const trafficSourceType = trafficSource?.type;
  const trafficSourceGclid = trafficSource?.gclid;

  if (link.match(/^http[s]?:\/\/(www.)?awin1.com/g)) {
    forwardLink += `&clickref=${encodeURIComponent(product.id)}`;
    if (userId) {
      forwardLink += `&clickref2=${encodeURIComponent(userId)}`;
    }
    if (trafficSourceType) {
      forwardLink += `&clickref3=${encodeURIComponent(trafficSourceType)}`;
    }
    if (trafficSourceGclid) {
      forwardLink += `&clickref4=${encodeURIComponent(trafficSourceGclid)}`;
    }
  } else if (link.match(/.*blbn\.495126\.504102.*/g)) {
    forwardLink += `&smc1=${encodeURIComponent(product.id)}`;
    if (userId) {
      forwardLink += `&smc4=${encodeURIComponent(userId)}`;
    }
    if (trafficSourceType) {
      forwardLink += `&smc2=${encodeURIComponent(trafficSourceType)}`;
    }
    if (trafficSourceGclid) {
      forwardLink += `&smc3=${encodeURIComponent(trafficSourceGclid)}`;
    }
    if (typeof window !== "undefined" && window.location?.href) {
      forwardLink += `&smc5=${encodeURIComponent(window.location.href)}`;
    }
  }
  return forwardLink;
}

export function linkTokenToLink(token) {
  try {
    return JSON.parse(Base64.decode(decodeURIComponent(token)));
  } catch (e) {
    throw new Error(`Invalid token: ${token}\n${e}`);
  }
}
