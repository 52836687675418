import Button from "@material-ui/core/Button";
import ProductHref from "./ProductHref";
import React from "react";
import { TheCycleverseTheme } from "src/theme";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  openButton: {
    backgroundColor: theme.hightlightColorTertiary,
    color: theme.palette.primary.main,
    padding: "10px 50px",
    fontSize: "large",
    "& .MuiButton-endIcon": {
      marginLeft: 12,
    },
  },
  disabledButton: {
    backgroundColor: theme.unflashyColor,
  },
  openIcon: {
    filter: "invert(1)",
    fontSize: 12,
  },
  unavailable: {
    color: theme.alertColor,
  },
}));

type ProductLinkComponentProps = {
  product: any;
  text: string;
  endIcon?: any;
  overrideLink?: string;
  target?: string;
  buttonClassName?: string;
};

const ProductLinkComponent = (props: ProductLinkComponentProps) => {
  const { product, text, endIcon, overrideLink, target, buttonClassName } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");
  // const enabled = product.visibility && product.visibility.startsWith("public");
  var enabled = product.visibility != "hidden_disabled"; // always forward to shop for cookie runtime. See https://thecycleverse.slack.com/archives/C0123G6MNS2/p1665233130607759?thread_ts=1663408097.056619&cid=C0123G6MNS2

  if (product.advertiser.includes("Bergzeit")) {
    enabled = product.visibility && product.visibility.startsWith("public");
  }

  if (product.advertiser.includes("ROSE")) {
    enabled = true;
  }

  if (enabled) {
    return (
      <ProductHref product={product} overrideLink={overrideLink} target={target}>
        <Button component="div" className={clsx(classes.openButton, buttonClassName)} endIcon={endIcon}>
          {text}
        </Button>
      </ProductHref>
    );
  } else {
    return (
      <div>
        <Button
          disabled={true}
          component="div"
          className={clsx(classes.openButton, classes.disabledButton, buttonClassName)}
          endIcon={endIcon}
        >
          {text}
        </Button>
        <p className={classes.unavailable}>{t("productUnavailable")}</p>
      </div>
    );
  }
};

export default ProductLinkComponent;
