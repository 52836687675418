function normalizedPrice(price){
  return (price.amount / Math.pow(10, price.decimalOffset));
}

function priceAsFloat(price) {
  return (price.amount / Math.pow(10, price.decimalOffset)).toFixed(2);
}
function priceWithoutDiscountAsFloat(price) {
  return (price.amountWithoutDiscount / Math.pow(10, price.decimalOffset)).toFixed(2);
}

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.info(e);
  }
}

function priceAsString(t, locale, price, decimalCount, hideCurrency, numbersOnly) {
  const amount = priceAsFloat(price);

  if (!numbersOnly && amount == 0) {
    return t("common:noCosts");
  }

  let currency;
  switch (price.currency) {
    case "USD":
      currency = "$";
      break;
    case "EUR":
    default:
      currency = "€";
  }

  var str, amountString;
  switch (locale) {
    case "en":
      str = formatMoney(amount, decimalCount, ".", ",");
      if (hideCurrency) {
        amountString = `${str}`;
      } else {
        amountString = `${currency} ${str}`;
      }
      break;
    case "de":
    default:
      str = formatMoney(amount, decimalCount, ",", ".");
      if (hideCurrency) {
        amountString = `${str}`;
      } else {
        amountString = `${str} ${currency}`;
      }
  }

  return amountString;
}

function priceWithoutDiscountAsString(t, locale, price) {
  if (!price.amountWithoutDiscount) {
    return priceAsString(t, locale, price);
  }
  const discount = {
    amount: price.amountWithoutDiscount,
    currency: price.currency,
    decimalOffset: price.decimalOffset,
  };
  return priceAsString(t, locale, discount);
}

export function useTranslationWithLocale(t, locale) {
  const priceAsStringFn = (price, decimalCount, showCurrency, numbersOnly) => {
    return priceAsString(t, locale, price, decimalCount, showCurrency, numbersOnly);
  };
  const priceWithoutDiscountAsStringFn = (price) => {
    return priceWithoutDiscountAsString(t, locale, price);
  };
  return {
    priceAsString: priceAsStringFn,
    priceWithoutDiscountAsString: priceWithoutDiscountAsStringFn,
    priceAsFloat,
    priceWithoutDiscountAsFloat,
    normalizedPrice
  };
}

export default function useTranslationExtras(t, locale) {
  return useTranslationWithLocale(t, locale);
}
