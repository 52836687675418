import React, { useCallback, useEffect, useState } from "react";

import Analytics from "../../../lib/analytics/analytics";
import { TheCycleverseTheme } from "src/theme";
import { getProductLink } from "./useProductLink";
import { makeStyles } from "@material-ui/core/styles";
import { useAsync } from "react-use";
import useTranslation from "next-translate/useTranslation";
import useTranslationExtras from "src/translations/useTranslationExtras";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  openHref: {
    textDecoration: "inherit",
  },
}));

const ProductHref = (props) => {
  const { product, overrideLink, target } = props;
  const classes = useStyles();
  const { t, lang: locale } = useTranslation("common");
  const { priceAsFloat } = useTranslationExtras(t, locale);
  let link = overrideLink ? overrideLink : getProductLink(product);

  const userId = useAsync(async () => {
    return await Analytics.getUserId();
  }, []);

  if (!userId.loading && userId.value) {
    link = overrideLink ? overrideLink : getProductLink(product, userId.value);
  }

  const handleLinkClick = useCallback(
    (e) => {
      if (!overrideLink) {
        Analytics.productForward(product.id, product.seoId, product.title, {
          advertiser: product.advertiser,
          category: product.category,
          priceAmount: priceAsFloat(product.price),
          priceCurrency: product.price.currency,
        });
      }
    },
    [product, overrideLink, priceAsFloat]
  );

  return (
    <>
      <a
        href={link}
        rel="nofollow"
        className={classes.openHref}
        target={target ? target : "_blank"}
        onClick={handleLinkClick}
      >
        {props.children}
      </a>
    </>
  );
};

export default ProductHref;
